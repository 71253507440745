'use client';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Formik, FormikConfig, FormikValues } from 'formik';

import { doNothing } from '@bloom/ui/utils/empty-value';
import { flattenObject } from '@bloom/ui/utils/object';

export function FormState<V extends FormikValues = FormikValues>(
  props: FormikConfig<V> & { canWrite?: boolean }
): JSX.Element {
  const { canWrite = true, children, validate, ...restProps } = props;

  const [isSubmitClicked, setSubmitClicked] = useState(false);

  return (
    <Formik<V>
      enableReinitialize
      validate={(values) => {
        if (!isSubmitClicked) {
          setSubmitClicked(true);
        }
        if (typeof validate === 'function') {
          return validate(values);
        }
        return {};
      }}
      // Trigger validation on change and blur only
      // after Submit button was clicked.
      validateOnBlur={isSubmitClicked}
      validateOnChange={isSubmitClicked}
      {...restProps}
    >
      {({ errors, handleChange, isSubmitting, setFieldValue, ...restFormikProps }) => {
        const isSubmittingRef = useRef(isSubmitting);

        useEffect(() => {
          if (errors && isSubmittingRef.current && !isSubmitting) {
            const [firstErrorName] = Object.keys(flattenObject(errors));

            if (firstErrorName) {
              const formElement = document.querySelector(`[name="${firstErrorName}"]`);
              if (formElement) {
                formElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
              } else {
                // find the first invalid element and scroll to it
                document
                  .querySelector('[aria-invalid="true"]')
                  ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }
            }
          }

          isSubmittingRef.current = isSubmitting;
        }, [errors, isSubmitting]);

        const handleInputChange = useCallback(
          (e: React.ChangeEvent<HTMLInputElement>) => {
            if (canWrite) {
              handleChange(e);
            }
          },
          [canWrite, handleChange]
        );

        const handleSetFieldValue = useCallback(
          async (name: string, value: any) => {
            if (canWrite) {
              return setFieldValue(name, value);
            }

            return doNothing();
          },
          [canWrite, setFieldValue]
        );

        return typeof children === 'function'
          ? children({
              ...restFormikProps,
              errors,
              handleChange: handleInputChange,
              isSubmitting,
              setFieldValue: handleSetFieldValue,
            })
          : null;
      }}
    </Formik>
  );
}
